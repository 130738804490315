import * as THREE from "three";
import gsap from "gsap";
import Experience from "../Experience.js";
import "./Chapters.css";
import VideoPlayer from "./VideoPlayer.js";
import HumanoidFat from "./HumaniodFat.js";
import Options from "./Options.js";

export default class Chapters {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;
    this.camera = this.experience.camera;
    this.videoPlayer = new VideoPlayer();
    this.videoElem = document.getElementById("video");
    this.videoTexture = new THREE.VideoTexture(this.videoElem);
    this.videoPlayer.video.material.map = this.videoTexture;
    this.videoElemIntro = document.getElementById("videoIntro");
    this.videoElem.load();
    this.videoElemIntro.load();
    this.videoTextureIntro = new THREE.VideoTexture(this.videoElemIntro);
    this.videoPlayer.visible = true;
    this.videoElem.play();
    this.videoElem.pause();
    this.current = 0;
    this.details = [
      {
        header:
          "<a class='nutrition' target='__blank' href='https://en.wikipedia.org/wiki/Nutrition'>Nutrition </a>",
        body: "Our Bodies are our Gardens, <br> Our Wills are our Gardeners",
        button: "Explore",
      },
      {
        header: "",
        body: "",
        button: "Consequence",
      },
      {
        header: "Health versus Wellness",
        body: "To keep the body in good health is a duty... Otherwise we shall not be able to keep our mind strong and clear",
        button: "Heal",
      },
      {
        header: "",
        body: "",
        button: "Result",
      },
      {
        header: "Alamirap Nutrition",
        body: "Re-Engineering for Health and Wellness",
        button: "",
      },
      {
        header: "Alamirap Nutrition",
        body: "Re-Engineering for Health and Wellness",
        button: "",
      },
    ];
    this.i = document.getElementById("chd");
    this.j = document.getElementById("chmain");
    this.k = document.getElementById("chhead");
    this.l = document.getElementById("chbody");
    this.m = document.getElementById("chnext");
    this.n = document.getElementById("chbuttontext");
    this.o = document.getElementById("chcontent");
    this.p = document.getElementById("chbutton");
    this.HumanoidFat = new HumanoidFat();
    this.Options = new Options();
    this.HumanoidFat.model.visible = false;
    if (!this.experience.audioEnabled) {
      this.current = 5;
    }
    this.setupChapter();
    this.handlelistener = () => {
      this.p.removeEventListener("click", this.handlelistener);

      if (this.current == 0) {
        this.videoElem.play();
        this.videoElem.pause();
        this.fadeScene();
      } else if (this.current == 2) {
        this.videoElemIntro.play();
        this.videoElemIntro.pause();
        this.fadeScene();
      } else if (this.current !== 4) {
        this.fadeScene();
      }
    };
    this.p.addEventListener("click", this.handlelistener);
  }

  fadeScene() {
    var tl = gsap.timeline({
      onComplete: () => {
        this.current = this.current + 1;
        this.setupChapter();
      },
    });
    tl.to(this.experience.stars.material, {
      opacity: 0,
      duration: 1,
      ease: "Expo.easeInOut",
    });
    tl.to(
      this.experience.fog.material.uniforms.uOpacity,
      {
        duration: 1,
        value: 0.0,
        ease: "Expo.easeInOut",
      },
      "<"
    );
    tl.to(
      this.experience.light,
      {
        intensity: 0.658,
        duration: 1,
      },
      "<"
    );
    tl.to(this.experience.sun, {
      intensity: 0.658,
      duration: 1,
    });
    tl.to(
      this.j,
      {
        opacity: 0,
        duration: 1,
      },
      "<"
    );
    tl.play();
  }

  setupChapter() {
    this.i.style.display = "flex";
    this.k.innerHTML = this.details[this.current].header;
    this.l.innerHTML = this.details[this.current].body;
    this.n.innerHTML = this.details[this.current].button;
    var runAnimation = (current, currentLoop, nextBool, next, nextLoop) => {
      this.experience.humanoidAnimation.mixer.removeEventListener(
        "finished",
        function (e) {
          runAnimation();
        }
      );
      this.experience.humanoidAnimation.play(current, currentLoop, false);
      if (nextBool) {
        this.experience.humanoidAnimation.mixer.addEventListener(
          "finished",
          function (e) {
            runAnimation(next, nextLoop, false);
          }
        );
      }
    };

    switch (this.current) {
      case 0:
        this.experience.humanoidAnimation.play(
          "breathingIdle",
          THREE.LoopOnce,
          true
        );
        this.experience.humanoidAnimation.mixer.addEventListener(
          "finished",
          function (e) {
            runAnimation(
              "point",
              THREE.LoopOnce,
              true,
              "breathingIdle",
              THREE.LoopRepeat
            );
          }
        );
        gsap.to(this.j, { opacity: 1, duration: 3, delay: 6 });
        gsap.to(this.m, { opacity: 1, duration: 3, delay: 12 });
        break;
      case 1:
        if (this.experience.audioEnabled) {
          this.experience.start.pause();
        }
        this.o.style.display = "none";
        this.m.style.marginTop = "auto";
        this.m.style.marginBottom = "40px";
        gsap.to(this.experience.group.rotation, {
          x: 0.7,
          y: 2.7,
          z: 0,
          duration: 3,
          ease: "Expo.easeInOut",
          delay: 0.5,
          onComplete: () => {
            this.experience.human.model.visible = false;
            this.HumanoidFat.model.visible = true;
            this.videoElem.play();
            this.scene.fog = null;
          },
        });
        gsap.to(this.experience.group.position, {
          x: 0.07,
          y: -0.24,
          z: 0.68,
          duration: 3,
          ease: "Expo.easeIn",
          delay: 0.5,
        });
        gsap.to(this.j, {
          opacity: 1,
          duration: 3,
          delay: 20,
          onComplete: () => {
            this.p.addEventListener("click", this.handlelistener);
          },
        });
        break;
      case 2:
        this.o.style.display = "flex";
        this.m.style.marginTop = "20px";
        let delaytime = 3;
        this.videoElem.pause();
        if (this.experience.audioEnabled) {
          this.experience.audioIntro.play();
          delaytime = 47;
        } else {
          delaytime = 3;
        }
        gsap.to(this.experience.group.rotation, {
          x: 0,
          y: 0,
          z: 0,
          duration: 3,
          ease: "Expo.easeIn",
          onComplete: () => {
            this.videoPlayer.video.material.map = this.videoTextureIntro;
            this.videoPlayer.video.material.color.set(0x0e1415);
            //color:0e1415
          },
        });
        gsap.to(this.experience.group.position, {
          x: 0,
          y: 0,
          z: 0,
          duration: 3,
          ease: "Expo.easeInOut",
        });
        gsap.to(this.experience.stars.material, {
          opacity: 1,
          duration: 1,
          delay: 3,
          ease: "Expo.easeInOut",
        });
        gsap.to(this.experience.fog.material.uniforms.uOpacity, {
          duration: 1,
          value: 0.3,
          delay: 3,
          ease: "Expo.easeInOut",
        });
        gsap.to(this.experience.sun, {
          intensity: 2.5,
          duration: 1,
          delay: 3,
        });
        gsap.to(this.experience.light, {
          intensity: 9,
          duration: 2,
          delay: 3,
        });
        gsap.to(this.j, {
          opacity: 1,
          duration: 2,
          delay: delaytime,
          onComplete: () => {
            this.p.addEventListener("click", this.handlelistener);
          },
        });
        break;
      case 3:
        this.o.style.display = "none";
        this.m.style.marginTop = "auto";
        this.m.style.marginBottom = "40px";
        gsap.to(this.experience.group.rotation, {
          x: 0.7,
          y: 2.7,
          z: 0,
          duration: 3,
          ease: "Expo.easeInOut",
          delay: 0.5,
          onComplete: () => {
            this.experience.human.model.visible = true;
            this.HumanoidFat.model.visible = false;
            this.videoElemIntro.play();
            this.scene.fog = null;
          },
        });
        gsap.to(this.experience.group.position, {
          x: 0.07,
          y: -0.24,
          z: 0.68,
          duration: 3,
          ease: "Expo.easeIn",
          delay: 0.5,
        });

        gsap.to(this.j, {
          opacity: 1,
          duration: 2,
          delay: 88,
          onComplete: () => {
            this.p.addEventListener("click", this.handlelistener);
          },
        });
        break;
      case 4:
        this.experience.humanoidAnimation.play(
          "happyIdle",
          THREE.LoopRepeat,
          false
        );
        this.videoElemIntro.pause();
        this.o.style.display = "flex";
        this.m.style.display = "none";

        gsap.to(this.experience.group.rotation, {
          x: 0,
          y: 0,
          z: 0,
          duration: 3,
          ease: "Expo.easeIn",
          onComplete: () => {
            if (this.experience.audioEnabled) {
              this.experience.inspiration.play();
              this.Options.navlogo.click();
            }
          },
        });
        gsap.to(this.experience.group.position, {
          x: 0,
          y: 0,
          z: 0,
          duration: 3,
          ease: "Expo.easeInOut",
        });
        gsap.to(this.experience.stars.material, {
          opacity: 1,
          duration: 1,
          delay: 3,
          ease: "Expo.easeInOut",
        });
        gsap.to(this.experience.fog.material.uniforms.uOpacity, {
          duration: 1,
          value: 0.3,
          delay: 3,
          ease: "Expo.easeInOut",
        });
        gsap.to(this.experience.sun, {
          intensity: 4.5,
          duration: 1,
          delay: 3,
        });
        gsap.to(this.experience.light, {
          intensity: 9,
          duration: 2,
          delay: 3,
        });
        gsap.to(this.j, { opacity: 1, duration: 3, delay: 3 });
        break;
      case 5:
        gsap.to(this.j, {
          opacity: 1,
          duration: 3,
          delay: 3,
          onComplete: () => {
            this.Options.navlogo.click();
          },
        });
        this.o.style.display = "flex";
        this.m.style.display = "none";
    }
  }
  update() {
    if (this.HumanoidFat) this.HumanoidFat.update();
    if (this.Woman) this.Woman.update();
  }
}
