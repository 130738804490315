import gsap from "gsap/all";
import Experience from "../Experience.js";
import "./TwoDimElements.css";

export default class TwoDimElements {
  constructor() {
    this.experience = new Experience();
    this.twoDframe = document.getElementById("twoDsl");
    this.twoDframe.style.display = "flex";
    this.playtimerbar = document.getElementById("doorf");
    this.soundcontrol = document.getElementById("audio-c");
    this.soundcontrol.style.display = "flex";
    this.showAudioControls();
  }

  showAudioControls() {
    let gl = new gsap.timeline({
      delay: 2,
    });
    gl.to(this.twoDframe, { duration: 5, opacity: 1 });
    gl.to(this.playtimerbar, { duration: 3, opacity: 1 }, "<");
    gl.to(this.soundcontrol, { duration: 3, opacity: 1 }, "<");
    gl.play();
    document.getElementById("ac-enable").addEventListener("click", (e) => {
      this.audioSelected(true);
      e.target.style.color = "#ffffff";
    });
    document.getElementById("ac-disable").addEventListener("click", (e) => {
      this.audioSelected(false);
      e.target.style.color = "#ffffff";
    });
  }

  audioSelected(bool) {
    document.getElementById("ac-enable").removeEventListener("click", () => {
      this.audioSelected(true);
    });
    document.getElementById("ac-disable").removeEventListener("click", () => {
      this.audioSelected(false);
    });
    this.experience.audioEnabled = bool;
    if (bool) {
      this.intro = new Audio("/audio/intro.mp3");
      this.extro = new Audio("/audio/extro.mp3");
      this.start = new Audio("/audio/start.mp3");
      this.inspiration = new Audio("/audio/inspiration.mp3");

      this.experience.audioIntro = this.intro;
      this.experience.audioExtro = this.extro;
      this.experience.start = this.start;
      this.experience.start.play();
      this.experience.inspiration = this.inspiration;
    }
    let gl2 = new gsap.timeline({
      onComplete: () => {
        this.experience.resources.trigger("Audio-ready");
        document.body.style.boxShadow = "0 0 100px 15px var(--vignette) inset";
        this.twoDframe.style.display = "none";
        this.soundcontrol.style.display = "none";
        let i = document.getElementById("lld");
        i.style.display = "flex";
        gsap.to(i, { duration: 2, opacity: 1 });
        this.animateLogo();
      },
      delay: 1,
    });
    gl2.to(this.playtimerbar, {
      duration: 2,
      width: this.experience.sizes.diagonal + "px",
    });
    gl2.to(this.twoDframe, { duration: 2, opacity: 0 }, ">");

    gl2.to(this.soundcontrol, { opacity: 0, duration: 2 }, "<");
    gl2.to(this.playtimerbar, { duration: 2, rotation: "90" }, ">");
    gl2.to(
      this.playtimerbar,
      {
        duration: 4,
        height: this.experience.sizes.width + 25 + "px",
        ease: "Power2.easeInOut",
      },
      ">"
    );
    gl2.to(this.soundcontrol, { duration: 2, opacity: 0 }, "<");
    gl2.to(
      this.experience,
      { duration: 3, watercurrent: 40, ease: "Power2.easeInOut" },
      "<"
    );

    gl2.play();
  }

  animateLogo() {
    let moveXpos = [80, 70, 60, 50, 40, 30, 20, 10];
    let moveXneg = [65, 45, 25, 10, -8, -30, -48, -65];
    let moveY = 25;
    this.timegsap = gsap.timeline({
      repeat: -1,
      repeatDelay: 15,
      yoyo: true,
      delay: 5,
    });
    for (let i = 0; i < 8; i++) {
      var a = document.getElementById("ll" + (8 - i));
      var tl = gsap.timeline({
        repeat: 0,
        yoyo: false,
        delay: i * 2,
      });
      tl.to(a, { x: moveXpos[7 - i], duration: 2, ease: "sine.out" })
        .to(a, { y: moveY, duration: 2, ease: "sine.out" })
        .to(a, { x: moveXneg[7 - i], duration: 2, ease: "sine.out" });
      this.timegsap.add(tl, 1);
    }
    this.timegsap.play();
  }
}
