import * as THREE from "three";
import Experience from "../Experience.js";

export default class HumanoidFat {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;
    this.time = this.experience.time;
    this.camera = this.experience.camera;
    this.debug = this.experience.debug;

    // Debug
    if (this.debug.active) {
      this.debugFolder = this.debug.ui.addFolder("HumanoidFat");
    }

    // Resource
    this.resource = this.resources.items.HumanoidFatModel;

    this.setModel();
    this.setAnimation();
  }

  setModel() {
    this.model = this.resource.scene;
    this.model.scale.set(0.0022, 0.0022, 0.0022);
    this.model.position.set(-0.018, -0.171, -0.09);
    this.model.rotation.set(0, 0.132, 0);
    this.model.castShadow = true;
    this.model.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        child.castShadow = true;
        child.material.vertexColors = false;
        child.material.emissiveIntensity = 1;
        child.material.color.r = 0.36;
        child.material.color.g = 0.36;
        child.material.color.b = 0.36;
        child.material.emissive.r = 0.05;
        child.material.emissive.g = 0.05;
        child.material.emissive.b = 0.05;
        child.material.metalness = 0.49;
        child.material.roughness = 0.72;
        if (this.debug.active) {
          this.debugFolder.addColor(child.material, "color").name("Color");
          this.debugFolder
            .addColor(child.material, "emissive")
            .name("EmissiveColor");
          this.debugFolder
            .add(child.material, "emissiveIntensity")
            .name("EmissiveIntensity")
            .min(0)
            .max(1)
            .step(0.001);
          this.debugFolder
            .add(child.material, "metalness")
            .name("Metalness")
            .min(0)
            .max(1)
            .step(0.001);
          this.debugFolder
            .add(child.material, "roughness")
            .name("Roughness")
            .min(0)
            .max(1)
            .step(0.001);
        }
      }
    });
    this.experience.group.add(this.model);

    if (this.debug.active) {
      this.debugFolder
        .add(this.model.position, "x")
        .name("posX")
        .min(-2)
        .max(2)
        .step(0.001);

      this.debugFolder
        .add(this.model.position, "y")
        .name("posY")
        .min(-2)
        .max(2)
        .step(0.001);

      this.debugFolder
        .add(this.model.position, "z")
        .name("posZ")
        .min(-2)
        .max(2)
        .step(0.001);
      this.debugFolder
        .add(this.model.scale, "x")
        .name("scale")
        .min(0)
        .max(2)
        .step(0.0001)
        .onChange(() => {
          this.model.scale.y = this.model.scale.z = this.model.scale.x;
        });
      this.debugFolder
        .add(this.model.rotation, "y")
        .name("rotY")
        .min(-4)
        .max(4)
        .step(0.001);
    }
  }

  setAnimation() {
    this.animation = {};

    // Mixer
    this.animation.mixer = new THREE.AnimationMixer(this.model);
    //this.experience.animationMixer = this.animation.mixer;

    // Actions
    this.animation.actions = {};
    this.animation.actions.sadIdle = this.animation.mixer.clipAction(
      this.resource.animations[0]
    );
    this.animation.actions.current = this.animation.actions.sadIdle;

    // Play the action
    this.animation.play = (name) => {
      const newAction = this.animation.actions[name];
      const oldAction = this.animation.actions.current;

      newAction.reset();
      newAction.play();
      newAction.crossFadeFrom(oldAction, 1);

      this.animation.actions.current = newAction;
    };

    this.animation.play("sadIdle");
    // Debug
    if (this.debug.active) {
      const debugObject = {
        playsadIdle: () => {
          this.animation.play("sadIdle");
        },
      };
      this.debugFolder.add(debugObject, "playsadIdle");
    }
  }

  update() {
    this.animation.mixer.update(this.time.delta * 0.001);
  }
}
