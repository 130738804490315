import Experience from "./Experience.js";

export default class Palette {
  constructor() {
    this.primarycolor = 0x274045;
    this.secondarycolor = 0xffffff;
    this.secondarydarkcolor = 0x808080;
    this.alternativeblue = 0x388b9c;
    this.alternativebluedark = 0x16505c;
    this.black = 0x000000;
    this.water = 0x0c2227;
  }
}
