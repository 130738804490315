import * as THREE from "three";
import Experience from "../Experience.js";

export default class Humanoid {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;
    this.time = this.experience.time;
    this.camera = this.experience.camera;
    this.debug = this.experience.debug;

    // Debug
    if (this.debug.active) {
      this.debugFolder = this.debug.ui.addFolder("Humanoid");
    }

    // Resource
    this.resource = this.resources.items.HumanoidModel;

    this.setModel();
    this.setAnimation();
  }

  setModel() {
    this.model = this.resource.scene;
    this.model.scale.set(0.0023, 0.0023, 0.0023);
    // this.model.position.set(-0.018, -0.171, -0.09);
    this.model.position.set(0.015, -0.171, -0.09);
    this.model.rotation.set(0, 0.132, 0);
    this.model.castShadow = true;
    this.model.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        child.castShadow = true;
        child.material.vertexColors = false;
        child.material.emissiveIntensity = 1;
        child.material.color.r = 0.15;
        child.material.color.g = 0.15;
        child.material.color.b = 0.15;
        child.material.emissive.r = 0.05;
        child.material.emissive.g = 0.05;
        child.material.emissive.b = 0.05;
        child.material.metalness = 0.49;
        child.material.roughness = 0.72;
        if (this.debug.active) {
          this.debugFolder.addColor(child.material, "color").name("Color");
          this.debugFolder
            .addColor(child.material, "emissive")
            .name("EmissiveColor");
          this.debugFolder
            .add(child.material, "emissiveIntensity")
            .name("EmissiveIntensity")
            .min(0)
            .max(1)
            .step(0.001);
          this.debugFolder
            .add(child.material, "metalness")
            .name("Metalness")
            .min(0)
            .max(1)
            .step(0.001);
          this.debugFolder
            .add(child.material, "roughness")
            .name("Roughness")
            .min(0)
            .max(1)
            .step(0.001);
        }
      }
    });
    this.experience.group.add(this.model);
    this.resources.trigger("Humanoid-ready");

    if (this.debug.active) {
      this.debugFolder
        .add(this.model.position, "x")
        .name("posX")
        .min(-2)
        .max(2)
        .step(0.001);

      this.debugFolder
        .add(this.model.position, "y")
        .name("posY")
        .min(-2)
        .max(2)
        .step(0.001);

      this.debugFolder
        .add(this.model.position, "z")
        .name("posZ")
        .min(-2)
        .max(2)
        .step(0.001);
      this.debugFolder
        .add(this.model.scale, "x")
        .name("scale")
        .min(0)
        .max(2)
        .step(0.0001)
        .onChange(() => {
          this.model.scale.y = this.model.scale.z = this.model.scale.x;
        });
      this.debugFolder
        .add(this.model.rotation, "y")
        .name("rotY")
        .min(-4)
        .max(4)
        .step(0.001);
    }
  }

  setAnimation() {
    this.animation = {};

    // Mixer
    this.animation.mixer = new THREE.AnimationMixer(this.model);
    this.experience.animationMixer = this.animation.mixer;

    // Actions
    this.animation.actions = {};

    this.animation.actions.breathingIdle = this.animation.mixer.clipAction(
      this.resource.animations[2]
    );

    this.animation.actions.point = this.animation.mixer.clipAction(
      this.resource.animations[1]
    );
    this.animation.actions.happyIdle = this.animation.mixer.clipAction(
      this.resource.animations[0]
    );
    this.animation.actions.current = this.animation.actions.breathingIdle;
    this.experience.humanoidAnimation = this.animation;

    // Play the action
    this.animation.play = (name, loop, clampWhenFinished) => {
      const newAction = this.animation.actions[name];
      const oldAction = this.animation.actions.current;
      newAction.reset();
      newAction.setLoop(loop);
      newAction.clampWhenFinished = clampWhenFinished;
      newAction.play();
      newAction.crossFadeFrom(oldAction, 1, true);
      this.animation.actions.current = newAction;
    };
    // Debug
    if (this.debug.active) {
      const debugObject = {
        playbreathingIdle: () => {
          this.animation.play("breathingIdle", THREE.LoopOnce, true);
        },
        playwaving: () => {
          this.animation.play("waving", THREE.LoopOnce, true);
        },
        playhappyIdle: () => {
          this.animation.play("happyIdle", THREE.LoopOnce, true);
        },
        playstandUp: () => {
          this.animation.play("standUp", THREE.LoopOnce, true);
        },
      };
      this.debugFolder.add(debugObject, "playbreathingIdle");
      this.debugFolder.add(debugObject, "playwaving");
      this.debugFolder.add(debugObject, "playhappyIdle");
      this.debugFolder.add(debugObject, "playstandUp");
    }
  }

  update() {
    this.animation.mixer.update(this.time.delta * 0.001);
  }
}
