import gsap from "gsap";
import * as THREE from "three";
import Experience from "../Experience.js";

import fogVertexShader from "./Shaders/fogvertex.glsl";
import fogFragmentShader from "./Shaders/fogfragment.glsl";

export default class Environment {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;
    this.camera = this.experience.camera;
    this.debug = this.experience.debug;
    this.time = this.experience.time;

    // Debug
    if (this.debug.active) {
      this.debugFolder = this.debug.ui.addFolder("environment");
    }

    this.loaded = 0;
    this.toLoad = 4;
    this.setSunLight();
    this.setEnvironmentMap();
    this.setFog();
    this.setStars();
  }

  updateLoadStatus() {
    this.loaded += 1;
    if (this.loaded == this.toLoad) this.resources.trigger("env-ready");
  }
  setSunLight() {
    this.sunLight = new THREE.DirectionalLight("#e3faff", 4.5);
    this.sunLight.castShadow = true;
    this.sunLight.shadow.camera.far = 15;
    this.sunLight.shadow.mapSize.set(1024, 1024);
    this.sunLight.shadow.normalBias = 0.05;
    this.sunLight.position.set(0.674, 1.692, 0.42);
    this.scene.add(this.sunLight);
    this.secondLight = new THREE.DirectionalLight("#ffe4ab", 9);
    this.secondLight.castShadow = true;
    this.secondLight.shadow.camera.far = 15;
    this.secondLight.shadow.mapSize.set(1024, 1024);
    this.secondLight.shadow.normalBias = 0.05;
    this.secondLight.position.set(-1.48, -0.089, 1.947);
    this.scene.add(this.secondLight);
    this.experience.sun = this.sunLight;
    this.experience.light = this.secondLight;
    this.updateLoadStatus();

    // Debug
    if (this.debug.active) {
      this.debugFolder
        .add(this.sunLight, "intensity")
        .name("sunLightIntensity")
        .min(0)
        .max(10)
        .step(0.001);

      this.debugFolder
        .add(this.sunLight.position, "x")
        .name("sunLightX")
        .min(-5)
        .max(5)
        .step(0.001);

      this.debugFolder
        .add(this.sunLight.position, "y")
        .name("sunLightY")
        .min(-5)
        .max(5)
        .step(0.001);

      this.debugFolder
        .add(this.sunLight.position, "z")
        .name("sunLightZ")
        .min(-5)
        .max(5)
        .step(0.001);
      this.debugFolder
        .add(this.secondLight, "intensity")
        .name("secondLightIntensity")
        .min(0)
        .max(10)
        .step(0.001);

      this.debugFolder
        .add(this.secondLight.position, "x")
        .name("secondLightX")
        .min(-5)
        .max(5)
        .step(0.001);

      this.debugFolder
        .add(this.secondLight.position, "y")
        .name("secondLightY")
        .min(-5)
        .max(5)
        .step(0.001);

      this.debugFolder
        .add(this.secondLight.position, "z")
        .name("secondLightZ")
        .min(-5)
        .max(5)
        .step(0.001);
    }
  }

  setEnvironmentMap() {
    this.environmentMap = {};
    this.environmentMap.intensity = 2.328;
    this.environmentMap.texture = this.resources.items.environmentMapTexture;
    this.environmentMap.texture.encoding = THREE.sRGBEncoding;

    this.scene.environment = this.environmentMap.texture;

    this.environmentMap.updateMaterials = () => {
      this.scene.traverse((child) => {
        if (
          child instanceof THREE.Mesh &&
          child.material instanceof THREE.MeshStandardMaterial
        ) {
          child.material.envMap = this.environmentMap.texture;
          child.material.envMapIntensity = this.environmentMap.intensity;
          child.material.needsUpdate = true;
        }
      });
    };
    this.environmentMap.updateMaterials();
    this.updateLoadStatus();

    // Debug
    if (this.debug.active) {
      this.debugFolder
        .add(this.environmentMap, "intensity")
        .name("envMapIntensity")
        .min(0)
        .max(4)
        .step(0.001)
        .onChange(this.environmentMap.updateMaterials);
    }
  }
  setFog() {
    this.scene.fog = new THREE.Fog(
      this.experience.palette.primarycolor,
      0.33,
      3.6
    );
    this.fogtexture = this.resources.items.fogMap;
    this.fogPlane = new THREE.PlaneGeometry(3, 3, 128, 128);
    this.uniforms = {
      uAlphaMap: {
        type: "sampler2D",
        value: this.fogtexture,
      },
      uColor: {
        type: "vec3",
        value: new THREE.Color(this.experience.palette.secondarycolor),
      },
      uOpacity: {
        type: "float",
        value: 0.0,
      },
      uTime: {
        type: "float",
        value: 0.0,
      },
      uWaves: {
        type: "float",
        value: 0.33,
      },
      uFrequency: { type: "vec2", value: new THREE.Vector2(3.14, 3.14) },
      uTime: { type: "float", value: 0.0 },
    };
    this.waveTime = 0.00108;
    this.fogMaterial = new THREE.ShaderMaterial({
      vertexShader: fogVertexShader,
      fragmentShader: fogFragmentShader,
      uniforms: this.uniforms,
      transparent: true,
      depthWrite: false,
    });
    this.fogMaterial.side = THREE.DoubleSide;
    this.fog = new THREE.Mesh(this.fogPlane, this.fogMaterial);
    this.fog.position.set(0, 0, -1.0);
    this.experience.group.add(this.fog);
    this.experience.fog = this.fog;
    //this.scene.add(this.experience.fog);
    this.updateLoadStatus();

    if (this.debug.active) {
      this.debugFolder
        .add(this.scene.fog, "far")
        .name("fogFar")
        .min(0)
        .max(4)
        .step(0.001);

      this.debugFolder
        .add(this.scene.fog, "near")
        .name("fogNear")
        .min(0)
        .max(4)
        .step(0.001);
      this.debugFolder
        .add(this.fog.position, "z")
        .name("fogZ")
        .min(-10)
        .max(10)
        .step(0.001);
      this.debugFolder
        .add(this.fogMaterial.uniforms.uWaves, "value")
        .min(0)
        .max(1)
        .step(0.001)
        .name("Waves");
      this.debugFolder
        .add(this.fogMaterial.uniforms.uFrequency.value, "x")
        .min(0)
        .max(10)
        .step(0.001)
        .name("uFrequencyX");
      this.debugFolder
        .add(this.fogMaterial.uniforms.uFrequency.value, "y")
        .min(0)
        .max(10)
        .step(0.001)
        .name("uFrequencyY");
      this.debugFolder
        .add(this, "waveTime")
        .name("WaveTime")
        .min(0.0000001)
        .max(0.01)
        .step(0.000001);
    }
  }

  setStars() {
    this.starGeometry = new THREE.BufferGeometry();
    this.count = 300;
    let positions = new Float32Array(this.count * 3);
    for (let i = 0; i < this.count * 3; i++) {
      positions[i] = (Math.random() - 0.5) * 1.5;
    }
    this.starGeometry.setAttribute(
      "position",
      new THREE.BufferAttribute(positions, 3)
    );
    this.starTexture = this.resources.items.starTexture;
    this.starMaterial = new THREE.PointsMaterial({
      color: new THREE.Color(this.experience.palette.primarycolor),
      size: 0.015,
      sizeAttenuation: true,
      alphaMap: this.starTexture,
      transparent: true,
      depthWrite: false,
      opacity: 0,
    });
    this.stars = new THREE.Points(this.starGeometry, this.starMaterial);
    this.stars.position.set(0, 0, -1.1);
    this.experience.group.add(this.stars);
    this.experience.stars = this.stars;
    //this.scene.add(this.stars);
    this.shootingStarMaterial = new THREE.SpriteMaterial({
      alphaMap: this.starTexture,
      transparent: true,
      depthWrite: true,
      opacity: 0,
    });
    this.shootingStar = new THREE.Sprite(this.shootingStarMaterial);
    this.shootingStar.scale.set(0.005, 0.005, 0.005);
    this.shootingStar.position.set(0, 0, -0.7);
    this.experience.group.add(this.shootingStar);
    this.updateLoadStatus();

    //animation
    setInterval(() => {
      let i = Math.floor(Math.random() * 6);
      if (i == 1 || i == 4) {
        this.shootingStar.position.set(
          (Math.random() - 0.5) * 0.8,
          (Math.random() - 0.5) * 0.8,
          -0.9
        );
        gsap.to(this.shootingStar.material, {
          opacity: 1,
          duration: 3,
        });
        gsap.to(this.shootingStar.position, {
          x: (Math.random() - 0.5) * 1,
          y: (Math.random() - 0.5) * 1,
          duration: 2,
          delay: 1,
        });
        gsap.to(this.shootingStar.material, {
          opacity: 0,
          duration: 1,
          delay: 2,
        });
      }
    }, 4000);
  }

  update() {
    this.stars.rotation.z = -this.time.elapsed * 0.000018;
    this.uniforms["uTime"].value = this.time.elapsed * this.waveTime;
  }
}
