import * as THREE from "three";
import gsap from "gsap";
import Experience from "../Experience";

export default class Options {
  constructor() {
    this.experience = new Experience();
    this.navlogo = document.getElementById("navb");
    this.navoptions = document.getElementById("navo");
    this.navbg = document.getElementById("navcurtain");
    this.navright = document.getElementById("navright");
    this.navleft = document.getElementById("navleft");
    this.close = document.getElementById("close");
    this.smlink = document.getElementById("smlink");
    this.wtlink = document.getElementById("wtlink");
    this.aboutimage = document.getElementById("aboutimage");
    this.reviewcontainer = document.getElementById("reviewcontainer");
    this.navOpen = false;

    this.navlogo.addEventListener("click", () => {
      if (this.navOpen) {
        this.navClose();
      } else {
        this.navOpen = true;
        this.navoptions.style.display = "flex";
        gsap.to(this.navoptions, { opacity: 1, duration: 1 });
        gsap.to(this.navlogo, { opacity: 1, duration: 1 });
      }
    });
    this.navClose = () => {
      gsap.to(this.navoptions, {
        opacity: 0,
        duration: 1,
        onComplete: () => {
          this.navoptions.style.display = "none";
        },
      });
      gsap.to(this.navlogo, { opacity: 0.5, duration: 1 });
      this.navOpen = false;
    };
    let anchors = document.getElementsByClassName("nav-o-b");
    for (let i = 0; i < anchors.length; i++) {
      let anchor = anchors[i];
      anchor.onclick = (e) => {
        this.navigate(e.target.id);
      };
    }

    this.close.addEventListener("click", () => {
      let aboutustext = document.getElementById("data");
      let aboutimage = document.getElementById("aboutimage");
      if (this.experience.audioEnabled) {
        this.experience.audioExtro.pause();
      }
      gsap.to(aboutustext, {
        opacity: 0,
        duration: 0.2,
        onComplete: () => {
          this.navleft.innerHTML = "";
          aboutimage.style.display = "none";
        },
      });
      gsap.to(aboutimage, {
        opacity: 0,
        duration: 0.2,
      });
      gsap.to(this.close, {
        opacity: 0,
        duration: 0.2,
      });
      gsap.to(this.reviewcontainer, { opacity: 0, duration: 0.2 });
      let tl = gsap.timeline({
        delay: 0.2,
        onComplete: () => {
          this.navbg.style.display = "none";
        },
      });
      tl.to(this.navleft, { width: "0%", opacity: 1, duration: 2 });
      tl.to(this.navright, { width: "0%", opacity: 1, duration: 2 }, "<");
      tl.play();
    });

    this.data = {
      aboutus: {
        left: "<div class='data' id='data'><div class='data-name'>Dr. Parimala Jaggesh <span class='hctag'>(H.C)</span></div><div class='data-tag'>Founder - ALAMIRAP Nutrition Pvt. Ltd., <br> Founder - G.L.A.M DIET &#169;</div><div class='data-content'>If there is one thing that life has taught me - 'Change is the Only Constant'. Even though I belong to Sandalwood movie fraternity as my husband, Mr. Jaggesh is an actor and director since almost 40 years, my interests have always been varied. I believe, man's greatest wealth in life is good health. The reason for this belief is: I was diagnosed as a type-2 diabetic in 1994 when my youngest son was hardly 2 years old. It has been almost 3 decades now and I am proud to state I have successfully beaten all odds and reversed my dependency on insulin solely due to nutrition and lifestyle modifications. With my vision of three E's - encouraging, educating, and empowering my clients, I started ALAMIRAP (my name written backwards) Nutrition company, as we firmly believe, we can help anyone, at any age, reverse their way back to health and wellness. <br><br>ACE certified - Weight Management Specialist<br>NASM - Certified Nutrition Coach<br>IISDT - Diabetes Educator<br>CIIS - California - Health & Wellness Coach<br>ICF Certified - Positive Psychology Coach<br>ICF Certified - Life Coach<br>Winner of National Awards<br>Featured in various print and visual media <br>Chosen amongst India's 50 Top Emerging Icons <br>Featured on the cover page of Silicon India Healthcare Magazine <br><br><br><br><a class='article' href='https://cdrpj.org//article.asp?issn=2773-1316;year=2022;volume=1;issue=2;spage=69;epage=75;aulast=Jaggesh;type=3#' target='__blank'>Article</a></div></div>",
        right: "",
        mobile:
          "<div class='data' id='data'><div class='data-name'>Dr. Parimala Jaggesh <span class='hctag'>(H.C)</span></div><div class='data-tag'>Founder - ALAMIRAP Nutrition Pvt. Ltd., G.L.A.M DIET &#169;</div><div class='data-content'><br>ACE certified - Weight Management Specialist<br><br>NASM - Certified Nutrition Coach<br><br>IISDT - Diabetes Educator<br><br>CIIS - California - Health & Wellness Coach<br><br>ICF Certified - Positive Psychology Coach<br><br>ICF Certified - Life Coach<br><br>Winner of National Awards<br><br>Featured in various print and visual media <br><br>Chosen amongst India's 50 Top Emerging Icons <br><br>Featured on the cover page of Silicon India Healthcare Magazine <br><br></div></div>",
      },
      services: {
        left: "<div class='data' id='data'>A well balanced diet is the key to a healthy lifestyle.<br>We have a dedicated and passionate team of doctors, registered dietians, physiotherapists, gym trainers, yoga instructors, who work in tandem to bring about a holistic transformation in our client's lives.<br><br> We provide personalised tailor-made nutrition plans based on your health requirements such as:<br><br>Weight Loss<br>Post Pregnancy Weight Management<br>Weight Gain<br>Thyroid management<br>PCOD management<br>Bodybuilding Nutrition<br>Sports Nutrition<br>Child Nutrition<br>Bridal healthcare<br>General Health & Wellness<br>Celebrity Nutrition<br><br><span class='package'>Packages start from ₹ 5,999 onwards</span><br><br><br><a class='article' href='https://api.whatsapp.com/send/?phone=%2B917760711199&text&type=phone_number&app_absent=0' target='__blank'>Whatsapp</a><br><br><a href='/PDF/AlamirapServices.pdf' class='article' target='_blank'>Know More</a></div>",
        right: "",
        mobile: "<div class='close-button' id='close'>CLOSE</div>",
      },
    };
  }

  navigate(id) {
    switch (id) {
      case "0":
        this.navbg.style.display = "flex";
        this.navClose();
        if (this.experience.audioEnabled) {
          setTimeout(() => {
            this.experience.audioExtro.currentTime = 0;
            this.experience.audioExtro.play();
            this.experience.audioIntro.pause();
          }, 4000);
        }
        let tl = gsap.timeline({
          onComplete: () => {
            if (window.innerWidth < 800) {
              this.navleft.innerHTML = this.data.aboutus.mobile;
            } else {
              this.navleft.innerHTML = this.data.aboutus.left;
            }
            let aboutustext = document.getElementById("data");
            this.reviewcontainer.style.display = "none";
            this.aboutimage.style.display = "flex";
            gsap.to(aboutustext, { opacity: 1, duration: 1 });
            gsap.to(this.aboutimage, { opacity: 1, duration: 2 });
            gsap.to(this.close, { opacity: 1, duration: 2 });
          },
        });
        tl.to(this.navleft, { width: "50%", opacity: 1, duration: 2 });
        tl.to(this.navright, { width: "50%", opacity: 1, duration: 2 }, "<");
        tl.play();
        break;

      case "1":
        this.navbg.style.display = "flex";
        this.navClose();
        let tl1 = gsap.timeline({
          onComplete: () => {
            this.navleft.innerHTML = this.data.services.left;
            let servicestext = document.getElementById("data");
            this.reviewcontainer.style.display = "flex";
            aboutimage.style.display = "none";
            if (window.innerWidth < 1024) {
              this.reviewcontainer.innerHTML =
                "<a class='article' href='https://www.google.com/search?q=Alamirap+Nutrition target='_blank'>Link to Google Reviews</a>";
            }
            gsap.to(servicestext, { opacity: 1, duration: 1 });
            gsap.to(this.close, { opacity: 1, duration: 2 });
            gsap.to(this.reviewcontainer, { opacity: 1, duration: 2 });
          },
        });
        tl1.to(this.navleft, { width: "50%", opacity: 1, duration: 2 });
        tl1.to(this.navright, { width: "50%", opacity: 1, duration: 2 }, "<");
        tl1.play();
        break;

      case "2":
        console.log("blog");
        break;
      case "3":
        this.smlink.style.display = "flex";
        gsap.to(this.smlink, { opacity: 1, duration: 1 });
        this.smlink.addEventListener("mouseleave", () => {
          gsap.to(this.smlink, {
            opacity: 0,
            duration: 1,
            onComplete: () => {
              this.smlink.style.display = "none";
            },
          });
        });
        break;
      case "4":
        this.wtlink.style.display = "flex";
        gsap.to(this.wtlink, { opacity: 1, duration: 1 });
        this.wtlink.addEventListener("mouseleave", () => {
          gsap.to(this.wtlink, {
            opacity: 0,
            duration: 1,
            onComplete: () => {
              this.wtlink.style.display = "none";
            },
          });
        });
        break;
    }
  }
}
