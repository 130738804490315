import * as THREE from "three";
import Experience from "../Experience.js";

export default class Boulder {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;
    this.time = this.experience.time;
    this.camera = this.experience.camera;
    this.debug = this.experience.debug;

    // Debug
    if (this.debug.active) {
      this.debugFolder = this.debug.ui.addFolder("Pedestal");
    }

    // Resource
    this.resource = this.resources.items.PedestalModel;
    this.setModel();
  }

  setModel() {
    this.model = this.resource.scene;
    this.model.receiveShadow = true;
    this.model.scale.set(0.0493, 0.0493, 0.0493);
    this.model.position.set(0.018, -0.737, -0.114);
    this.model.rotation.y = -0.887;
    this.model.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        child.castShadow = true;
        child.receiveShadow = true;
        child.material.color.r = 0;
        child.material.color.b = 0;
        child.material.color.g = 0;
        if (this.debug.active) {
          this.debugFolder.addColor(child.material, "color").name("Color");
        }
      }
    });
    this.experience.group.add(this.model);
    this.resources.trigger("pedestal-ready");

    if (this.debug.active) {
      this.debugFolder
        .add(this.model.position, "x")
        .name("posX")
        .min(-2)
        .max(2)
        .step(0.001);

      this.debugFolder
        .add(this.model.position, "y")
        .name("posY")
        .min(-2)
        .max(2)
        .step(0.001);

      this.debugFolder
        .add(this.model.position, "z")
        .name("posZ")
        .min(-2)
        .max(2)
        .step(0.001);
      this.debugFolder
        .add(this.model.scale, "x")
        .name("scale")
        .min(0)
        .max(2)
        .step(0.0001)
        .onChange(() => {
          this.model.scale.y = this.model.scale.z = this.model.scale.x;
        });
      this.debugFolder
        .add(this.model.rotation, "y")
        .name("rotY")
        .min(-4)
        .max(4)
        .step(0.001);
    }
  }
}
