import Experience from "../Experience.js";
import Environment from "./Environment.js";
import Humanoid from "./Humanoid.js";
import Pedestal from "./Pedestal.js";
import LoadingScreen from "./LoadingScreen.js";
import TwoDimElements from "./TwoDimElements.js";
import gsap from "gsap/all";
import Chapters from "./Chapters.js";
import * as THREE from "three";

export default class World {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;
    this.camera = this.experience.camera;
    this.debug = this.experience.debug;
    this.loading = true;
    this.LoadingScreen = new LoadingScreen();
    this.setResources();

    // Debug
    if (this.debug.active) {
      this.debugFolder = this.debug.ui.addFolder("World");
    }
  }

  setResources() {
    // Wait for resources
    this.resources.on("resources-ready", () => {
      setTimeout(() => {
        this.environment = new Environment();
      }, 2000);
    });

    this.resources.on("env-ready", () => {
      setTimeout(() => {
        this.Pedstal = new Pedestal();
        gsap.to(this.LoadingScreen.elem, { duration: 1, opacity: 0 });
        gsap.to(this.LoadingScreen.elem3, {
          duration: 1,
          opacity: 0,
          delay: 2,
        });
        gsap.to(this.LoadingScreen.logo, { duration: 1, opacity: 0, delay: 2 });
      }, 2000);
    });

    this.resources.on("pedestal-ready", () => {
      setTimeout(() => {
        this.Humanoid = new Humanoid();
        this.experience.human = this.Humanoid;
      }, 1000);
    });

    this.resources.on("Humanoid-ready", () => {
      this.TwoDimElements = new TwoDimElements();
    });
    this.resources.on("Audio-ready", () => {
      if (!this.experience.audioEnabled) {
        this.experience.humanoidAnimation.play(
          "happyIdle",
          THREE.LoopRepeat,
          false
        );
      }
      this.loading = false;
      this.LoadingScreen.destroy();
      this.scene.add(this.experience.group);
      let camX = 0;
      let camY = 0.09;
      let camZ = 1.062;
      if (window.innerWidth < 1024) {
        camX = 0;
        camY = 0.08;
        camZ = 1.36;
      }
      gsap.to(this.camera.instance.position, {
        x: camX,
        y: camY,
        z: camZ,
        duration: 6,
        ease: "Power4.out",
        onComplete: () => {
          gsap.to(this.experience.stars.material, {
            opacity: 1,
            duration: 5,
            onComplete: () => {
              this.resources.trigger("scene-ready");
            },
          });
          gsap.to(this.experience.fog.material.uniforms.uOpacity, {
            duration: 5,
            value: 0.3,
          });
        },
      });
      let i = document.getElementById("doord");
      gsap.to(i, {
        duration: 1,
        opacity: 0,
        onComplete: () => {
          i.style.display = "none";
        },
      });
    });

    this.resources.on("scene-ready", () => {
      this.Chapters = new Chapters();

      // Debug
      if (this.debug.active) {
        this.debugFolder
          .add(this.experience.group.rotation, "y")
          .name("RotY")
          .min(-10)
          .max(10)
          .step(0.1);
        this.debugFolder
          .add(this.experience.group.rotation, "x")
          .name("RotX")
          .min(-10)
          .max(10)
          .step(0.1);
        this.debugFolder
          .add(this.experience.group.rotation, "z")
          .name("RotZ")
          .min(-10)
          .max(10)
          .step(0.1);
        this.debugFolder
          .add(this.experience.group.position, "x")
          .name("PosX")
          .min(-10)
          .max(10)
          .step(0.01);
        this.debugFolder
          .add(this.experience.group.position, "y")
          .name("PosY")
          .min(-10)
          .max(10)
          .step(0.01);
        this.debugFolder
          .add(this.experience.group.position, "z")
          .name("PosZ")
          .min(-10)
          .max(10)
          .step(0.01);
      }
    });
  }

  update() {
    if (this.Humanoid) this.Humanoid.update();
    if (this.loading) this.LoadingScreen.update();
    if (this.environment) this.environment.update();
    if (this.Chapters) this.Chapters.update();
  }
}
