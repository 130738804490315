import * as THREE from "three";
import Experience from "../Experience.js";

export default class VideoPlayer {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.camera = this.experience.camera;
    this.resources = this.experience.resources;
    this.debug = this.experience.debug;
    // Debug
    if (this.debug.active) {
      this.debugFolder = this.debug.ui.addFolder("Video");
    }
    this.setup();
  }

  setup() {
    this.videoGeometry = new THREE.PlaneGeometry(1.77, 1, 20, 20);
    this.planeCurve(this.videoGeometry, 0.3);
    this.videoMaterial = new THREE.MeshBasicMaterial({
      color: 0x101719,
      transparent: true,
    });
    this.video = new THREE.Mesh(this.videoGeometry, this.videoMaterial);
    this.video.position.set(0.427, -0.508, 0.82);
    this.video.rotation.set(0.75, 3.4, 0.3);
    this.video.scale.set(0.5, 0.5, 0.5);
    if (window.innerWidth < 850) {
      this.video.scale.set(0.3, 0.3, 0.3);
      this.video.position.set(0.48, -0.412, 0.82);
    }

    this.experience.group.add(this.video);

    if (this.debug.active) {
      this.debugFolder
        .add(this.video.position, "x")
        .name("posX")
        .min(-2)
        .max(2)
        .step(0.001);

      this.debugFolder
        .add(this.video.position, "y")
        .name("posY")
        .min(-2)
        .max(2)
        .step(0.001);

      this.debugFolder
        .add(this.video.position, "z")
        .name("posZ")
        .min(-2)
        .max(2)
        .step(0.001);
      this.debugFolder
        .add(this.video.scale, "x")
        .name("scale")
        .min(0)
        .max(2)
        .step(0.0001)
        .onChange(() => {
          this.video.scale.y = this.video.scale.z = this.video.scale.x;
        });
      this.debugFolder
        .add(this.video.rotation, "x")
        .name("rotX")
        .min(-5)
        .max(5)
        .step(0.001);
      this.debugFolder
        .add(this.video.rotation, "y")
        .name("rotY")
        .min(-5)
        .max(5)
        .step(0.001);
      this.debugFolder
        .add(this.video.rotation, "z")
        .name("rotZ")
        .min(-5)
        .max(5)
        .step(0.001);
      this.debugFolder.addColor(this.video.material, "color").name("Color");
    }
  }

  planeCurve(g, z) {
    let p = g.parameters;
    let hw = p.width * 0.5;

    let a = new THREE.Vector2(-hw, 0);
    let b = new THREE.Vector2(0, z);
    let c = new THREE.Vector2(hw, 0);

    let ab = new THREE.Vector2().subVectors(a, b);
    let bc = new THREE.Vector2().subVectors(b, c);
    let ac = new THREE.Vector2().subVectors(a, c);

    let r =
      (ab.length() * bc.length() * ac.length()) / (2 * Math.abs(ab.cross(ac)));

    let center = new THREE.Vector2(0.1, z - r);
    let baseV = new THREE.Vector2().subVectors(a, center);
    let baseAngle = baseV.angle() - Math.PI * 0.5;
    let arc = baseAngle * 2;

    let uv = g.attributes.uv;
    let pos = g.attributes.position;
    let mainV = new THREE.Vector2();
    for (let i = 0; i < uv.count; i++) {
      let uvRatio = 1 - uv.getX(i);
      let y = pos.getY(i);
      mainV.copy(c).rotateAround(center, arc * uvRatio);
      pos.setXYZ(i, mainV.x, y, -mainV.y);
    }
  }
}
