import * as THREE from "three";
import gsap from "gsap";
import Experience from "../Experience.js";
import { Water } from "../../../node_modules/three/examples/jsm/objects/Water.js";

export default class LoadingScreen {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.camera = this.experience.camera;
    this.time = this.experience.time;
    this.resources = this.experience.resources;
    this.debug = this.experience.debug;
    this.sizes = this.experience.sizes;
    this.previousprogress = 0;
    this.z = 0;
    this.elem = document.getElementById("lpb");
    this.elem2 = document.getElementById("lpn");
    this.elem3 = document.getElementById("lpq");
    this.elem2.style.display = "flex";
    this.logohead = document.getElementById("lplih");
    this.logobody = document.getElementById("lplib");
    this.logo = document.getElementById("logo");
    // Debug
    if (this.debug.active) {
      this.debugFolder = this.debug.ui.addFolder("loadingscreen");
    }

    this.setLogo();
    var animation = bodymovin.loadAnimation({
      container: document.getElementById("lottie"), // Required
      path: "/JSON/data.json", // Required
      renderer: "svg", // Required
      loop: true, // Optional
      autoplay: true, // Optional
    });
    gsap.to(this.elem3, { opacity: 1, duration: 1, delay: 3 });

    this.resources.on("progress", () => {
      this.updateLoadPercent();
    });
  }

  setLogo() {
    let sunDirection = new THREE.Vector3(1, 2, 2);
    this.waterGeometry = new THREE.PlaneGeometry(50, 50);
    this.waterTexture = new THREE.TextureLoader().load(
      "/textures/water/waternormals.jpg"
    );
    this.waterTexture.wrapS = this.waterTexture.wrapT = THREE.RepeatWrapping;

    this.water = new Water(this.waterGeometry, {
      textureWidth: 512,
      textureHeight: 512,
      waterNormals: this.waterTexture,
      sunDirection: sunDirection,
      sunColor: this.experience.palette.black,
      waterColor: this.experience.palette.water,
      distortionScale: 3.7,
    });
    this.water.rotation.x = -Math.PI / 2;
    this.water.material.transparent = true;
    this.water.position.set(0, -3, 5);
    this.experience.water = this.water;
    this.scene.add(this.water);

    if (this.debug.active) {
      this.debugFolder
        .add(sunDirection, "x")
        .name("SunX")
        .min(-5)
        .max(10)
        .step(0.0001);
      this.debugFolder
        .add(sunDirection, "y")
        .name("SunY")
        .min(-5)
        .max(10)
        .step(0.0001);
      this.debugFolder
        .add(sunDirection, "z")
        .name("SunZ")
        .min(-5)
        .max(10)
        .step(0.0001);
      this.debugFolder
        .add(this.water.material.uniforms.distortionScale, "value")
        .name("distortionScale")
        .min(-10)
        .max(10)
        .step(0.001);
      this.debugFolder
        .add(this.experience, "watercurrent")
        .name("Current")
        .min(20)
        .max(1000)
        .step(20);
      this.debugFolder
        .addColor(this.water.material.uniforms.sunColor, "value")
        .name("SunColor");
      this.debugFolder
        .addColor(this.water.material.uniforms.waterColor, "value")
        .name("WaterColor");
      this.debugFolder
        .add(this.water.scale, "x")
        .name("scale")
        .min(0)
        .max(2)
        .step(0.0001)
        .onChange(() => {
          this.water.scale.y = this.water.scale.z = this.water.scale.x;
        });
      this.debugFolder
        .add(this.water.material, "opacity")
        .min(0)
        .max(1)
        .step(0.01);
      this.debugFolder
        .add(this.water.position, "x")
        .name("WaterposX")
        .min(-10)
        .max(10)
        .step(0.001);
      this.debugFolder
        .add(this.water.position, "y")
        .name("WaterposY")
        .min(-10)
        .max(10)
        .step(0.001);
      this.debugFolder
        .add(this.water.position, "z")
        .name("WaterposZ")
        .min(-10)
        .max(10)
        .step(0.001);
    }
  }

  updateLoadPercent() {
    let dif = this.resources.progress - this.previousprogress;
    let counter = 0;
    var interval = setInterval(() => {
      this.z += 1;
      this.elem.style.width = this.z + "%";
      counter++;
      if (counter == dif) {
        clearInterval(interval);
      }
    }, 10);
    this.previousprogress = this.resources.progress;
  }

  update() {
    this.water.material.uniforms["time"].value +=
      1.0 / this.experience.watercurrent;
  }

  destroy() {
    this.scene.remove(this.water);
    this.waterGeometry.dispose();
    this.waterTexture.dispose();
    let i = document.getElementById("lpn");
    i.style.display = "none";
  }
}
